import React from "react";
// import { HelmetDatoCms } from "gatsby-source-datocms";
// import Img from 'gatsby-image'
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";

const Post = ({ data, pageContext }) => {
  const { next, prev } = pageContext;
  const coverImg = data.datoCmsCaseStudy.coverImage ? data.datoCmsCaseStudy.coverImage : false;
  console.log(pageContext);
  return (
    <Layout navColor="white">
      <article id="paper">
        <div className="paper">
          {/* <HelmetDatoCms seo={data.datoCmsCaseStudy.seoMetaTags} /> */}

          <h1 className="paper_title">{data.datoCmsCaseStudy.title} {data.datoCmsCaseStudy.company}</h1>

          <div
            className="paper_hook"
            dangerouslySetInnerHTML={{
              __html:
                data.datoCmsCaseStudy.excerptNode.childMarkdownRemark.html
            }}
          />
        </div>
        {coverImg && (
          <div className="paper_featured" style={data.datoCmsCaseStudy.coverImage.customData} >
            <img src={data.datoCmsCaseStudy.coverImage.url} alt={data.datoCmsCaseStudy.coverImage.alt} />
          </div>
        )}
        <div className="paper">
          <div
            className="sheet__body"
            dangerouslySetInnerHTML={{
              __html:
                data.datoCmsCaseStudy.descriptionNode.childMarkdownRemark.html
            }}
          />
        </div>
      </article>
      <aside className="paper_footer">
        <span className="text-section uppercase text-small text-bold text-sans-serif">
          More case studies
        </span>
        <div className="flex">
          {prev && (
            <div className="col col-padding-right">
              <Link to={`/case-study/${prev.slug}`}>
                <h4>{prev.title} {prev.company}</h4>
                <p className="case_study_link">Read case study</p>
              </Link>
            </div>
          )}

          {next && (
            <div className="col col-padding-left">
              <Link to={`/case-study/${next.slug}`}>
                <h4>{next.title} {next.company}</h4>
                <p className="case_study_link">Read case study</p>
              </Link>
            </div>
          )}
        </div>
      </aside>
    </Layout>
  );
};

export const query = graphql`
  query WorkQuery($slug: String!) {
    datoCmsCaseStudy(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      company
      excerptNode {
        childMarkdownRemark {
          html
        }
      }
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
      coverImage {
        url
        alt
        customData
      }
    }
  }
`;

export default Post;
